<template>
<div>
    <div class="diagnosis">
        <a-layout>
            <a-layout-content>
                <a-row class="s-layout">
                    <a-col :span="16" :offset="4" style="text-align:center">
                        <span class="s-d-font1">스토어링크 분석 기능을 통해<br />
                            적합한 마케팅 방법을 확인 해 보세요</span>
                        <div style="width:70%;margin: 0 auto;margin-top:30px" class="s-d-btn1">
                            <a-input-search v-model="keywordName" placeholder="키워드를 입력해주세요" @search="invokeSearch">
                                <a-button slot="enterButton" style="color:white" disabled>
                                    분석하기
                                </a-button>
                            </a-input-search>
                        </div>
                    </a-col>

                    <a-col :span="16" :offset="4" style="margin-top:80px">
                        <div class="s-d-font2">
                            채널 추천
                        </div>
                        <div class="s-d-border1" style="margin-top:10px;text-align:center">
                            <div class="s-d-empty"><img src="@/assets/images/icon/icon-empty.png"><br />
                                <span>데이타가 없습니다.</span></div>
                        </div>
                    </a-col>

                    <a-col :span="16" :offset="4" style="margin-top:70px">
                        <div class="s-d-font2">
                            예상 성과
                        </div>
                        <div style="margin-top:10px">
                            <a-row>
                                <a-col :span="6" style="padding-right:10px">
                                    <div class="s-d-border2" v-if="1==2">

                                    </div>
                                    <div class="s-d-border2 s-d-empty" style="text-align:center" v-else><img src="@/assets/images/icon/icon-empty.png"><br />
                                        <span>데이타가 없습니다.</span></div>
                                </a-col>
                                <a-col :span="6" style="padding-right:10px">
                                    <div class="s-d-border2" v-if="1==2">

                                    </div>
                                    <div class="s-d-border2 s-d-empty" style="text-align:center" v-else><img src="@/assets/images/icon/icon-empty.png"><br />
                                        <span>데이타가 없습니다.</span></div>
                                </a-col>
                                <a-col :span="6" style="padding-right:10px">
                                    <div class="s-d-border2" v-if="1==2">

                                    </div>
                                    <div class="s-d-border2 s-d-empty" style="text-align:center" v-else><img src="@/assets/images/icon/icon-empty.png"><br />
                                        <span>데이타가 없습니다.</span></div>
                                </a-col>
                                <a-col :span="6">
                                    <div class="s-d-border2" v-if="1==2" style=" background-color:#0264fb">

                                    </div>

                                    <div class="s-d-border2 s-d-empty" style="text-align:center;" v-else><img src="@/assets/images/icon/icon-empty.png"><br />
                                        <span>데이타가 없습니다.</span></div>
                                </a-col>

                            </a-row>

                        </div>
                    </a-col>
                    <a-col :span="16" :offset="4" style="margin-top:70px">
                        <div class="s-d-font2">
                            예상 성과 비교<span class="s-d-font3">&nbsp;&nbsp;한달 매출 기준</span>
                        </div>
                        <div style="margin-top:10px">
                            <a-row>
                                <a-col :span="12" style="padding-right:5px">

                                    <div class="s-d-border3" v-if="1==2">

                                    </div>
                                    <div class="s-d-border3 s-d-empty2" style="text-align:center" v-else><img src="@/assets/images/icon/icon-empty.png"><br />
                                        <span>데이타가 없습니다.</span></div>
                                </a-col>
                                <a-col :span="12" style="padding-left:5px">
                                    <div class="s-d-border3" v-if="1==2">

                                    </div>
                                    <div class="s-d-border3 s-d-empty2" style="text-align:center" v-else><img src="@/assets/images/icon/icon-empty.png"><br />
                                        <span>데이타가 없습니다.</span></div>
                                </a-col>

                            </a-row>

                        </div>
                    </a-col>

                </a-row>
            </a-layout-content>
        </a-layout>
    </div>
    <nav-footer></nav-footer>
</div>
</template>

<script>
Date.prototype.addDays = function(days) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
};

String.prototype.trims = function() {
  return this.replace(/^\s+|\s+$/g, "");
};

import NavFooter from "../../layouts/nav/NavFooter";
import firebase from "firebase";
import {
  getCurrentTimeNew,
  getHex,
  replaceAll,
  showSuccess
} from "../components/fnc.js";

export default {
  data() {
    return {
      loading: false,
      keywordName: ""
    };
  },
  components: {
    NavFooter
  },
  mounted() {
    var self = this;
    showSuccess({
      notify: self.$vs.notify,
      msg: "준비중인 메뉴입니다."
    });
  },
  methods: {
    checkLicense() {
      if (
        this.$store.state.AppActiveUser.level <
          this.$store.state.AppActiveUser.currCount ||
        this.$store.state.AppActiveUser.remain < 0
      ) {
        this.$vs.notify({
          title: "라이센스",
          text:
            "라이센스가 만료되었거나 관심제품 수량이 현재 등급에 맞지 않습니다.",
          color: "danger",
          iconPack: "feather",
          icon: "icon-message-square"
        });

        return false;
      } else {
        return true;
      }
    }
  }
};
</script>

<style>
.diagnosis {
  background-image: url("../../assets/images/pages/bg-a.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.s-d-btn1 input {
  height: 60px;
}

.s-d-btn1 button {
  background-color: #0264fb !important;
  border-color: #0264fb !important;
  height: 60px;
  width: 153px;
}

.s-d-font1 {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 30px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -1.88px;
  text-align: center;
  color: #474747;
}

.s-d-font2 {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -1.25px;
  color: #000000;
}

.s-d-font3 {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.81px;
  color: #7d7d7d;
}

.s-d-border1 {
  width: 100%;
  height: 208px;
  border-radius: 12px;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.03);
  background-color: #ffffff;
}

.s-d-border2 {
  width: 100%;
  height: 190px;
  border-radius: 12px;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.03);
  background-color: #ffffff;
}

.s-d-border3 {
  width: 100%;
  height: 295px;
  border-radius: 12px;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.03);
  background-color: #ffffff;
}

.s-d-empty {
  padding-top: 65px;
}

.s-d-empty2 {
  padding-top: 110px;
}

.s-d-empty > img,
.s-d-empty2 > img {
  width: 33px;
  height: 42px;
}

.s-d-empty > span,
.s-d-empty2 > span {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -1px;
  color: #bfbfbf;
}
</style>
